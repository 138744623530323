<template>
  <v-card flat :loading="loading">
    <template v-if="!loading">
      <v-alert
        v-if="items.length == 0"
        type="error"
        text
        border="left"
        :prominent="!$vuetify.breakpoint.xs"
      >
        Du hast im momentan keine Maturaarbeit.
      </v-alert>
      <v-alert
        v-if="settings.titleNow"
        type="info"
        text
        border="left"
        :prominent="!$vuetify.breakpoint.xs"
      >
        Titel der Maturarbeiten <span v-if="settings.titleNow">können</span
        ><span v-else>konnten</span> bis am
        <DateValue :value="settings.titleEndDate" long />,
        {{ settings.titleEndTime }} Uhr angepasst werden.
      </v-alert>
      <v-alert v-else type="info" text :prominent="!$vuetify.breakpoint.xs">
        Momentan kannst du hier keine Änderungen machen
      </v-alert>
    </template>

    <v-row>
      <v-col
        v-for="item in items"
        :key="item.id"
        xl="3"
        lg="4"
        md="6"
        sm="6"
        cols="12"
        align-stretch
      >
        <ThesisCard :value="item" :settings="settings" @changed="fetchData()" />
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import ThesisCard from "@/components/ThesisCard.vue";
export default defineComponent({
  components: { DateValue, ThesisCard },
  data() {
    return {
      loading: false,
      items: [],
      settings: false,
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.settings = await this.apiList({
        resource: "thesis/settings",
      });
      if (this.$_hasRole("student")) {
        this.items = await this.apiList({
          resource: "thesis/thesis",
          query: `student=${this.$_profilePerson.id}`,
        });
      } else {
        this.items = await this.apiList({
          resource: "thesis/thesis",
          query: `teacher=${this.$_profilePerson.id}`,
        });
      }
      this.loading = false;
    },
  },
  async created() {
    this.fetchData();
  },
  async beforeRouteUpdate(to, from, next) {
    if (from.name === "ThesisTitleEdit" && to.params.update) {
      await this.fetchData();
    }
    next();
  },
});
</script>
